<template>
  <div>
    <div class="tableTitle">
      <span class="title">
        经销协议
      </span>
    </div>
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="经销协议"></FromLabel>
        <div class="allWidth">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件" v-model="postFrom.agreement_file_name" disabled>
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey" :data="data"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg" @click="openFile" v-if="postFrom.agreement_file_key">{{ postFrom.agreement_file_name }}-经销协议</span>
          <img v-if="postFrom.agreement_file_name" class="marginLeft20 finger" @click="delectImg" src="../../../assets/images/deleteLogistics.png" />
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="签约日期"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" @on-change="changeTime($event, 1)" :value="postFrom.signup_time"></DatePicker>
        </div>
        <FromLabel label="有效期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307" :options="options" @on-change="changeTime($event, 2)" :value="postFrom.valid_period"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="账期" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <InputNumber :max="999999" :min="0" :precision="0" v-model="postFrom.payment_days" :active-change="false" class="iviewIptWidth307"></InputNumber>
          <span class="afterWords10"> 天</span>
        </div>
        <FromLabel label="交易类型" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <Select class="iviewIptWidth307" v-model="postFrom.deal_type">
            <Option v-for="(item, index) in dealList" :key="index" :value="item.value">{{ item.name }}</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="当地进院最低价"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" type="number" class="iviewIptWidth307" v-model.trim="postFrom.lowest_price"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
        <FromLabel label="当地指导价"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" type="number" class="iviewIptWidth307" v-model.trim="postFrom.guide_price"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="当地省标价"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" type="number" class="iviewIptWidth307" v-model.trim="postFrom.provincial_price"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
        <FromLabel label="当地市标价"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" type="number" class="iviewIptWidth307" v-model.trim="postFrom.market_price"></i-input>
          <span class="afterWords10"> 元</span>
        </div>
      </div>
      <div class="formLine">
        <FromLabel label="备注"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth1075" v-model.trim="postFrom.agreement_remark"></i-input>
        </div>
      </div>
      <div class="formLine">
        <FromLabel label="产品临床使用科室"></FromLabel>
        <div class="allWidth">
          <Select class="iviewIptWidth1075" v-model="postFrom.dept_id" multiple filterable clearable @on-change="deptChange">
            <Option v-for="(item, index) in deptList" :key="index" :value="item.dept_id">{{ item.dept_name }}</Option>
          </Select>
        </div>
      </div>
    </div>
    <!--    查看图片-->
    <lookup-image v-if="imgStatus && postFrom.agreement_file_url" @closeImg="imgStatus = false" title="经销协议" :imgUrl="postFrom.agreement_file_url" @></lookup-image>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/newformUpload'
import LookupImage from '@/components/lookupImage'
export default {
  name: 'dealerInfo',
  components: {
    FromLabel,
    FormUpload,
    LookupImage
  },
  data() {
    return {
      imgStatus: false,
      periodList: [],
      options: {},
      dealList: [
        {
          name: '普通购销',
          value: '1'
        },
        // {
        //   name: '自寄售',
        //   value: '2'
        // },
        {
          name: '供方寄售',
          value: '3'
        },
        {
          name: '委托代销',
          value: '4'
        }
      ],
      deptList: [],
      license: [],
      isLoad: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      postFrom: {
        agreement_file_key: '',
        agreement_file_name: '',
        signup_time: '',
        valid_period: '',
        payment_days: '',
        deal_type: '', // 1 普通购销  2 自寄售  3 供方寄售  4 委托代销
        lowest_price: '',
        guide_price: '',
        provincial_price: '',
        market_price: '',
        agreement_remark: '',
        dept_id: []
      },
      data: {
        table_type: 9
      }
    }
  },
  mounted() {
    // this.$root.$off('productInfo')
    // this.$root.$on('productInfo', res => {
    //   console.log(this.postFrom, 'postFrom')
    //   let detali = res
    //   this.postFrom = {
    //     agreement_file_key: this.postFrom.agreement_file_key ? this.postFrom.agreement_file_key : detali.agreement_file_key,
    //     agreement_file_name: detali.agreement_file_name,
    //     signup_time: detali.signup_time ? this.$moment(detali.signup_time * 1000).format('YYYY-MM-DD') : '',
    //     valid_period: detali.valid_period ? this.$moment(detali.valid_period * 1000).format('YYYY-MM-DD') : '',
    //     payment_days: detali.payment_days,
    //     deal_type: detali.deal_type + '', // 1 普通购销  2 自寄售  3 供方寄售  4 委托代销
    //     lowest_price: detali.lowest_price,
    //     guide_price: detali.guide_price,
    //     provincial_price: detali.provincial_price,
    //     market_price: detali.market_price,
    //     agreement_remark: detali.agreement_remark,
    //     dept_id: detali.dept_id
    //   }
    // })
    this.$root.$off('reviewId')
    this.$root.$on('reviewId', res => {
      this.queryDetail(res)
    })
    this.queryDept()
    for (let i = 0; i < 12; i++) {
      this.periodList.push({
        name: `${i + 1}个月`,
        value: i + 1
      })
    }
  },
  methods: {
    delectImg() {
      this.postFrom.agreement_file_key = ''
      this.postFrom.agreement_file_name = ''
      this.postFrom.agreement_file_url = ''
    },
    deptChange(e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i] === 0 && i == e.length - 1 && i != 0) {
          this.postFrom.dept_id = [0]
          return
        }
        if (e[i] === 0 && i == 0 && e.length >= 2) {
          this.postFrom.dept_id = e.slice(1, e.length)
          return
        }
      }
      this.postFrom.dept_id = e
    },
    openFile() {
      window.open(this.postFrom.agreement_file_url)
    },
    queryDetail(id) {
      this.$http.get(this.$api.productProductDetail, { review_id: id }, true).then(res => {
        let detali = res.data
        this.postFrom = {
          agreement_file_key: detali.agreement_file_key,
          agreement_file_name: detali.agreement_file_name,
          agreement_file_url: detali.agreement_file_url,
          signup_time: detali.signup_time ? this.$moment(detali.signup_time * 1000).format('YYYY-MM-DD') : '',
          valid_period: detali.valid_period ? this.$moment(detali.valid_period * 1000).format('YYYY-MM-DD') : '',
          payment_days: detali.payment_days,
          deal_type: detali.deal_type + '', // 1 普通购销  2 自寄售  3 供方寄售  4 委托代销
          lowest_price: detali.lowest_price,
          guide_price: detali.guide_price,
          provincial_price: detali.provincial_price,
          market_price: detali.market_price,
          agreement_remark: detali.agreement_remark,
          dept_id: detali.dept_id
        }
      })
    },
    // 查询科室下拉
    queryDept() {
      this.$http.get(this.$api.productSearchDept, {}, true).then(res => {
        this.deptList = res.data
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.postFrom.signup_time = e
        that.postFrom.valid_period = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.postFrom.signup_time)
          }
        }
      } else {
        this.postFrom.valid_period = e
      }
    },
    // 上传注册证
    getKey(key, name, url) {
      this.postFrom.agreement_file_key = key
      this.postFrom.agreement_file_name = name
      this.postFrom.agreement_file_url = url
    }
  }
}
</script>

<style scoped lang="less">
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
</style>
